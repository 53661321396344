import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Global, css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import PortableText from '../components/portable-text';

export default function Page({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setLogoColorDark(true);
    theme.setMenuColorDark(true);
    theme.setBackgroundColor(null);
  }, []);

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: var(--gutter);
        padding: 0 var(--margin);
        margin-top: 30rem;

        @media (max-width: 900px) {
          margin-top: 14rem;
        }
      `}
    >
      <SEO
        title={data.page.title}
        description={(data.page._rawContent) ? data.page._rawContent.map(el => el.children).flat().map((el) => el?.text)[0] : null}
      />
      <h1
        className="type--large"
        css={css`
          grid-column-start: 3;
          grid-column-end: span 8;
          font-weight: normal;
          text-align: center;
          margin-bottom: 5rem;

          @media (max-width: 900px) {
            grid-column: span 12;
          }
        `}
      >{data.page.title}</h1>
      <PortableText
        css={css`
          grid-column-start: 3;
          grid-column-end: span 8;
          padding-bottom: 10rem;

          @media (max-width: 900px) {
            grid-column: span 12;
          }
        `}
        blocks={data.page._rawContent}
      />
    </div>
  )
}

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    page: sanityPage(slug: {current: {eq: $slug}}) {
      title
      _rawContent(resolveReferences: {maxDepth: 10})
    }
  }
`
